<template>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">

            <!-- Header -->
            <div class="row justify-content-center">
                <div class="col-12 col-md-12 col-lg-10 col-xl-10 text-center">

                    <!-- Pretitle -->
                    <h6 class="text-uppercase"></h6>

                    <!-- Title -->
                    <h1 class="mb-2">
                        Lets get your profile out there!
                    </h1>

                    <!-- Subtitle -->
                    <p class="mb-5">
                        There are a few different ways to get your profile with SMS, Email, Website, or Socially.
                    </p>

                </div>
            </div> <!-- / .row -->
            
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-10 col-xl-10 text-center">
                <p class="mb-5">
                  <b-input-group>
                    <b-form-input
                      id="shareUrl"
                      v-model="shareUrl"
                      name="shareUrl"
                      disabled
                      placeholder=""
                    />
                    <b-input-group-append>
                      <b-button v-clipboard="copyURL" variant="primary">Copy Link</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </p>
              </div>
            </div>    


            <!-- form
            <validation-observer ref="linkForm">
              <b-form id="linkForm" @submit.prevent="generateLink">
                <div class="row align-items-center">
                  <div class="col">
                    <div id="social-text" class="mt-2">
                        <validation-provider
                          #default="{ errors }"
                          name="social image"
                        >
                          <b-media no-body>
                            <b-media-aside>
                              <b-link>
                                <template>
                                <b-img
                                  ref="previewEl"
                                  rounded
                                  :src="(previewImage)?previewImage:null"
                                  height="80"
                                />
                                </template>
                              </b-link>
                            </b-media-aside>

                            <b-media-body class="mt-75 ml-75">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="$refs.refInputEl.$el.click()"
                              >
                                Upload
                              </b-button>

                              <b-form-file
                                ref="refInputEl"
                                v-model="scoialImageFile"
                                accept=".jpg, .png, .gif"
                                :hidden="true"
                                plain
                                :state="errors.length > 0 ? false:null"
                                @input="fileImageInput"
                              />

                              <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                            </b-media-body>
                          </b-media>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>

                        <b-form-group
                          class="mt-2"
                          label="Social Title"
                          label-for="title"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="social title"
                            rules="required"
                          >
                            <b-form-input
                              id="title"
                              v-model="socialTitle"
                              :state="errors.length > 0 ? false:null"
                              name="title"
                              placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="Social Description"
                          label-for="description"
                          description="Create a social link with an image, title, and description."
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="social description"
                            rules="required"
                          >
                          <b-form-textarea
                            id="description"
                            v-model="description"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                            rows="3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                    </div>
                    <button class="btn btn-primary mt-3 mb-5 float-right" type="submit" id="send-product-link">Generate Link</button>
                  </div>
                </div>
              </b-form>
            </validation-observer>
            -->
        </div>
        <b-modal id="image-crop-modal" width="500" ok-only ok-variant="secondary" ok-title="Cancel">
          <b-card>
            <b-card-text>
              <VueCropper v-show="scoialImageFile" ref="cropper" :aspectRatio="1/1" alt="Source Image"></VueCropper>
            </b-card-text>
            <b-button class="primary" @click="saveImage()">Crop</b-button>
          </b-card>
        </b-modal>
    </div>
</template>

<script>
import {
  BTab, 
  BTabs, 
  VBHover, 
  BForm, 
  BButton, 
  BFormTextarea, 
  BFormInput, 
  BFormGroup, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BMedia,
  BMediaAside,
  BMediaBody, 
  BLink,
  BFormFile,
  BCard,
  BCardText,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { getStorage, uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, setDoc, doc} from 'firebase/firestore';
import { getAuth } from "firebase/auth";

const db = getFirestore()
const storage = getStorage()
const auth = getAuth()

export default {
  components: {
    BTab,
    BTabs,
    VBHover,
    BForm,
    BButton, 
    BFormTextarea,
    BFormInput, 
    BFormGroup, 
    BInputGroup, 
    BInputGroupAppend,
    BImg,
    BMediaAside,
    BMediaBody, 
    BMedia,
    BLink,
    BFormFile,
    BCardText,
    BCard,
    BFormInvalidFeedback,
    VueCropper,
    ValidationProvider,
    ValidationObserver,
    required,
  },
  directives: {
    Ripple,
    'b-hover': VBHover
  },
  data() {
    return {
      isHovered: false,
      username: null, 
      shareUrl: null,
      merchantName: 'Robert Smith',
      phoneNumber: null,
      textMessage: null,
      socialTitle: null,
      previewImage: null,
      scoialImageFile: null,
      userData: null,
      description: ''
    }
  },
  mounted() {
    this.username = (auth && auth.currentUser)?auth.currentUser.displayName:null
    this.shareUrl = "https://hustleforge.com/profile/" + this.username 
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered
    },
    copyURL(){
      if(this.userData && !this.userData.sharedProfile){
        this.userData.sharedProfile = true
        setDoc(doc(db, "profiles", this.username), { sharedProfile:this.userData.sharedProfile }, { merge: true })
        localStorage.setItem('userData', JSON.stringify(this.userData))
      }
      return this.shareUrl
    },
    fileImageInput(file) {
      if (file && file.name) {
        this.processing = true
        this.$bvModal.show('image-crop-modal')

        // Read file
        const fr = new FileReader()
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          // this is to load image on the UI
          this.mime_type = file.type
          this.previewImage = fr.result
          this.$refs.cropper.replace(this.previewImage)
        })
      }
    },
    async generateLink() {
      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.linkForm.validate()
      if (!validateForm) return

      // TODO
    },
    saveImage(){
      try {
        const cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          // Upload file
          const metadata = { contentType: this.mime_type }
          const storageRef = ref(storage, 'socialLinks/' + user.displayName)
          const uploadTask = await uploadBytes(storageRef, blob, metadata)

          // Get the download URL
          const newImageUrl = await getDownloadURL(storageRef)
          this.userData.image = (newImageUrl)?newImageUrl:this.userData.image

          // Close Dialog
          this.$bvModal.hide('image-crop-modal')
        }, this.mime_type)

      } catch (e) {
        console.error(e);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Profile Image Upload Error `,
            icon: 'ImageIcon',
            variant: 'danger',
            text: 'There was an issue uploading your profile image please try again.',
          },
        })
      } finally {
        this.processing = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

